import React, { Fragment, useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import axios from "axios";
import { checkLoggedIn, getBaseURL } from "../../utils";
import CloseEye from "../../icons/CloseEye";
import OpenEye from "../../icons/OpenEye";
import { toast } from "react-toastify";
import CustomPhoneInput from "../../components/Input/CustomPhoneInput";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";
import { isValidNumber } from "libphonenumber-js";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import PageTitle from "../../components/pagetitle/PageTitle";

const ResetPassword = ({ configs }) => {
  const { ip_data } = configs;
  const push = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const valueRef = useRef();
  const [showPassword, setShowPassword] = useState(false);

  const [value, setValue] = useState({
    email: "",
    phone: "",
    new_password: "",
    confirm_password: "",
    countrycode: ip_data?.country_calling_code || "",
    mobile_otp: "",
    email_otp: "",
    COUNTRY_CODE: "",
    otpSent: false, //  to check if the otp is sent or not
  });

  const [loading, setLoading] = useState(false);

  const timerRef = useRef();

  const [countdown, setCountdown] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    // validator.showMessages();
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
      validators: {
        phoneNumber: {
          message: "The :attribute must be a valid phone number.",
          rule: (val, params, validator) => {
            return isValidNumber(
              `${val.toString()}`,
              valueRef?.current?.COUNTRY_CODE
            );
          },
          required: true,
        },
        strongPassword: {
          // Name the rule
          message:
            "The :attribute must be 8+ characters, include digits (0-9), letters (a-z, A-Z), and special characters (!@#$)",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
            );
          },
          messageReplace: (message, params) => message, // You can customize this further if needed
          required: true, // Makes the field required
        },
      },
    })
  );

  const handlePhoneChange = (phoneValue, data) => {
    // Extract the phone number without the dial code
    const dialCode = data.dialCode;
    const phoneWithoutDialCode = phoneValue.slice(data.dialCode.length);

    // Update the state with the new values
    setValue((prev) => ({
      ...prev,
      phone: phoneWithoutDialCode,
      countrycode: data.dialCode,
      dialCode: dialCode,
      COUNTRY_CODE: data?.countryCode?.toUpperCase(),
    }));
    valueRef.current = {
      phone: phoneWithoutDialCode,
      countrycode: data.dialCode,
      dialCode: dialCode,
      COUNTRY_CODE: data?.countryCode?.toUpperCase(),
    };
    // validator.showMessages();
  };

  const forgetPassword = async (e) => {
    e.preventDefault();
    // if (validator.allValid()) {
      setLoading(true);
      await axios
        .post(`forgetpassword`, {
          email: value.email
        })
        .then((res) => {
          setValue((prev) => ({ ...prev, otpSent: true }));
          setIsButtonDisabled(true);
          toast.success(res.data?.message);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        });
    
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      setLoading(true);
      await axios
        .post(`verifyregisterotp`, {
          email: value?.email,
          email_otp: value?.email_otp,
    
        })
        .then((res) => {
          setValue((prev) => ({ ...prev, otpVerified: true }));
          toast.success(res.data?.message);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        });
    } else {
      validator?.showMessages();
    }
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    await axios
      .post(`resendregisterotp`, {
        email: value?.email,
        countrycode: value?.countrycode,
        mobileno: value?.phone,
      })
      .then((res) => {
        toast.success(res.data?.message);
        setIsButtonDisabled(true);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    // if (!validator?.allValid()) {
    //   validator?.showMessages();
    //   setValue((prev) => ({ ...prev }));
    //   return;
    // }
    let updatedData = {
      email: value.email,
      newPassowrd: value.new_password,
      confirmPassword: value.confirm_password,
      email_otp: value.email_otp,
    };
    setLoading(true);
    await axios
      .post(`${getBaseURL()}v1/auth/updateuserpassword`, {
        ...updatedData,
      })
      .then((res) => {
        toast.success(res.data?.message);
        push("/login");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    let timer;
    if (
      value?.otpSent &&
      !value?.otpVerified &&
      isButtonDisabled &&
      countdown > 0
    ) {
      // Step 3: Start the countdown
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown <= 0) {
      // Step 4: Re-enable the button and reset the timer when countdown reaches 0
      setIsButtonDisabled(false);
      setCountdown(60); // Reset countdown to 60 seconds
    }

    // Cleanup the interval on component unmount or countdown end
    return () => clearInterval(timer);
  }, [isButtonDisabled, countdown]);

  useEffect(() => {
    if (checkLoggedIn()) {
      push("/");
    }
  }, []);

  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={"Reset Password"} pagesub={"forgot"} />
      <Footer />
      <Dialog open={true}>
        <button
          onClick={() => {
            push("/");
          }}
          className="btn btn-close"
          disabled={loading}
        >
          <i className="icon-icon_close"></i>
        </button>
        {value.otpSent ? (
          // ENTER OTP SECTON
          <div className="login-form p-24 mt-0">
            <div className="login-header mb-10">
              <h2>Enter OTP </h2>
            </div>
            <form className="contact-form" onSubmit={updatePassword}>
              <div className="form-field mb-14">
                <label>Email OTP *</label>
                <input
                  type={"text"}
                  value={value.email_otp}
                  name="email_otp"
                  onBlur={changeHandler}
                  onChange={changeHandler}
                  disabled={loading}
                />
                {validator.message("email_otp", value.email_otp, "required")}
              </div>
          
             
              <div className="form-field mb-12">
                <label>New Password *</label>
                <input
                  type={showPassword ? "text" : "password"}
                  value={value.new_password}
                  name="new_password"
                  onBlur={changeHandler}
                  onChange={changeHandler}
                  disabled={loading}
                />
                <button
                  className="eye-button"
                  type="button"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? <CloseEye /> : <OpenEye />}
                </button>
                {validator.message(
                  "new_password",
                  value.new_password,
                  "required|strongPassword"
                )}
              </div>
              <div className="form-field mb-12">
                <label>Confirm New Password *</label>
                <input
                  type={showPassword ? "text" : "password"}
                  value={value.confirm_password}
                  name="confirm_password"
                  onBlur={changeHandler}
                  onChange={changeHandler}
                  disabled={loading}
                />
                <button
                  className="eye-button"
                  type="button"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? <CloseEye /> : <OpenEye />}
                </button>
                {validator.message(
                  "confirm_password",
                  value.confirm_password,
                  `required|in:${value.new_password}`,
                  {
                    messages: {
                      in: "Passwords do not match.",
                    },
                    element: (message) =>
                      value.new_password &&
                      value.new_password === value.confirm_password ? (
                        <span style={{ color: "green", width: "100%" }}>
                          Passwords match.
                        </span>
                      ) : (
                        <span className="errorMessage">{message}</span>
                      ),
                  }
                )}
              </div>
              <div className="form-field mb-14 mb-0 mt-3">
                <button
                  style={{ width: "100%" }}
                  type="submit"
                  className="small-btn-style"
                  disabled={loading}
                >
                  {loading ? (
                    <PulseLoader color="white" size={10} />
                  ) : (
                    "Verify Otp"
                  )}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="login-form p-24 mt-0">
            <div className="login-header mb-10">
              <h2>Reset Password </h2>
            </div>
            <form onSubmit={forgetPassword}>
              <div className="form-field mb-14">
                <label>Email Address *</label>
                <input
                  type="email"
                  value={value.email}
                  name="email"
                  onChange={changeHandler}
                  disabled={loading}
                />
                {validator.message("email", value.email, "required|email")}
              </div>
           

              <div className="form-field mb-14 mb-0 mt-3">
                <button
                  style={{ width: "100%" }}
                  type="submit"
                  className="small-btn-style"
                  disabled={loading}
                >
                  {loading ? <PulseLoader color="white" size={10} /> : "Verify"}
                </button>
              </div>
            </form>
            {/* <Nav tabs className="login-tabs">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
                style={{ borderRadius: "30px 0px 0px 30px", padding: "4px" }}
              >
                <p
                  style={
                    activeTab === "1"
                      ? {
                          color: "#FAFAFA",
                          marginBottom: "0px",
                          border: "1px",
                          borderRadius: "30px",
                        }
                      : { marginBottom: "0px", fontWeight: "500" }
                  }
                >
                  Email
                </p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
                style={{ borderRadius: "0px 30px 30px 0px", padding: "4px" }}
              >
                <p
                  style={
                    activeTab === "2"
                      ? {
                          color: "#FAFAFA",
                          marginBottom: "0px",
                        }
                      : { marginBottom: "0px", fontWeight: "500" }
                  }
                >
                  Phone
                </p>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <form className="contact-form" onSubmit={forgetPassword}>
                <div className="form-field mb-14">
                  <label>Email Address *</label>
                  <input
                    type="email"
                    value={value.email}
                    name="email"
                    onChange={changeHandler}
                  />
                  {validator.message("email", value.email, "required|email")}
                </div>

                <div className="form-field mb-14">
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                    className="small-btn-style h-45"
                  >
                    Verify
                  </button>
                </div>
              </form>
            </TabPane>

            <TabPane tabId="2">
              <form className="contact-form" onSubmit={forgetPassword}>
                <div className="form-field mb-14">
                  <label>Phone Number *</label>
                  <input
                    type="number"
                    value={value.phone}
                    name="phone"
                    onChange={changeHandler}
                  />
                  {validator.message("phone", value.phone, "required|phone")}
                </div>

                <div className="form-field mb-14">
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                    className="small-btn-style h-45"
                  >
                    Verify
                  </button>
                </div>
              </form>
            </TabPane>
          </TabContent> */}
          </div>
        )}
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    configs: state.configs,
  };
};
export default connect(mapStateToProps)(ResetPassword);
